const api = {
  user: {
    login: '/Subsidiary/Login/login',
    logout: '/Login/logout',
    userInfo: '/Subsidiary/Login/self',
    addFiles: '/Subsidiary/Upload/addFiles',
    imgList: '/Subsidiary/image/list',
    shopInfo: '/Subsidiary/info', //店铺信息
    artisanList: '/Subsidiary/Artisan/list',
    addArtisan: '/Subsidiary/Artisan/add',
    artisanInfo: '/Subsidiary/Artisan/info',
    communityList: '/Subsidiary/Artisan/communityList',
    userList: '/Subsidiary/Artisan/userList',
    classifyList: '/Subsidiary/Artisan/classifyList',
    partnerList: '/Subsidiary/Partner/list',
    propertyList: '/Subsidiary/Property/list',
  },
  order: {
    setExpires: '/Store/Shop/setOrderExpires', //设置订单过期时间
    list: '/Store/order/list', //获取订单列表
    info: '/Store/order/info', //获取订单详情
    setSend: '/Store/order/setSend', //订单发货接口
    num: '/Store/order/stateNum', //订单数量
    express: '/Store/express/list', //物流信息
    logisticsQuery: '/Store/Order/logisticsQuery', //物流信息查询
    setSystemMessage: '/Store/Order/setSystemMessage', //添加系统备注信息
    changePrice: '/Store/Order/changePrice', //修改订单价格
    cancel: '/Store/Order/cancel', //取消未付款订单
    cancelList: '/Store/Orderrefundreason/list', //退款原因
    printOrder: '/Store/Order/printOrder', //打印订单
    handleRefund: '/Store/Orderrefund/handle', //处理退款
    salesList: '/Store/Orderrefund/list', //售后列表
    refundInfo: '/Store/Orderrefund/info', //退款详情
    receive: '/Store/Orderrefund/receive', //商家收货
    refund: '/Store/Orderrefund/refund', //原路退回到钱包
    confirmReceipt: '/Store/order/confirmReceipt', //订单确认收货
    refundNum: '/Store/Orderrefund/stateNum', //退款订单数量
    editTracking: '/Store/Order/editTracking', //编辑物流
  },
  service: {
      orderList: '/Subsidiary/ServiceOrder/getList',
      getNum: '/Subsidiary/ServiceOrder/getNum',
      orderInfo: '/Subsidiary/ServiceOrder/detail',
      cancleOrder: '/Subsidiary/ServiceOrder/cancle',
      orderRefundList: '/Subsidiary/ServiceOrderRefund/getList',
      orderRefundNum: '/Subsidiary/ServiceOrderRefund/getNum',
      orderRefundDeal: '/Subsidiary/ServiceOrderRefund/deal',
      orderRefundInfo: '/Subsidiary/ServiceOrderRefund/detail',
      orderComment: '/Subsidiary/ServiceOrderComment/getList',
      orderCommentReply: '/Subsidiary/ServiceOrderComment/reply',
      orderFinanceIncome: '/Subsidiary/ServiceOrder/financeIncome',
      communityList: '/Subsidiary/ServiceOrder/getCommunityList',
      artisanList: '/Subsidiary/ServiceOrder/getAtisanList',
      orderDispatch: '/Subsidiary/ServiceOrder/dispatchOrder',
      signBill: '/Subsidiary/ServiceOrder/signBill',
  },
  finance: {
    withdraw: '/Subsidiary/FinancialVerify/withdraw', //提现
    withdrawList: '/Subsidiary/PropertyWithdraw/getList', //提现记录
    withdrawInfo: '/Subsidiary/PropertyWithdraw/detail', //详情(提现记录)
    withdrawInfoList: '/Subsidiary/StoreWithdraw/infoList', //提现订单(详情)
    commodityIncome: '/Subsidiary/Finance/commodityIncome', //获取商品收益
    financialVerify: '/Subsidiary/FinancialVerify/getList',
    getBankCard: '/Subsidiary/FinancialVerify/getBankCard',//获取银行卡
    editBankCard: '/Subsidiary/FinancialVerify/editBankCard',//编辑银行卡列表
    getAmount: '/Subsidiary/FinancialVerify/getAmount',//获取可提现金额
    getCheckingDetail: '/Subsidiary/FinancialVerify/getDetail', //对账详情
    checking:'/Subsidiary/FinancialVerify/checking',
    getLogDetail: '/Subsidiary/PropertyWithdraw/getLogDetail', //对账详情
    propertyWithdrawDetailTop: '/Subsidiary/PropertyWithdraw/detail', //物业对账
    propertyWithdrawDetail: '/Subsidiary/PropertyWithdraw/getCashLogList', //物业对账
  },
  // 首页
  homeIndex: {
    readMsg: '/shop/readMessage',
  },
  // 首页
  index: {
    getData: '/Subsidiary/StatisticalData/getData', //获取统计首页
    getDateData: '/Subsidiary/StatisticalData/getDateData', //首页获取 按月 按年数据
  },
};

export default api;
