<template>
    <el-main>
        <div class="login-box">
            <form @submit="loginSubmit" class="login">
                <div class="title">
                    <p>智慧子公司登录</p>
                    <p>Welcome to login</p>
                </div>
                <div class="form">
                    <div class="password-input">
                        <div class="account">
                            <el-input v-model="loginForm.account" placeholder="请输入账号"></el-input>
                        </div>
                        <div class="code">
                            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
                        </div>
                        <div class="forget-password">
                            <div class="left">
                                <el-checkbox v-model="is_remember" @change="getremember"></el-checkbox>
                                <span>记住账号密码</span>
                            </div>
                        </div>
                    </div>
                    <div class="login-btn">
                        <button type="submit">登录</button>
                    </div>
                </div>
            </form>
        </div>
    </el-main>
</template>
<script>
export default {
    data () {
        return {
            is_remember: !1,
            loginForm: {
                account: '',
                password: '',
                code: '',
            },
        };
    },
    created () {
        if (sessionStorage.getItem('token')) this.$router.go(1);
        this.loginForm.account = localStorage.getItem('account');
        this.loginForm.password = localStorage.getItem('password');
        if (localStorage.getItem('password')) {
            this.is_remember = !0;
        }
    },
    methods: {
        getremember (val) {
            if (val) {
                localStorage.setItem('account', this.loginForm.account);
                localStorage.setItem('password', this.loginForm.password);
            } else {
                localStorage.removeItem('account');
                localStorage.removeItem('password');
            }
        },
        selectLoginType (i) {
            this.loginType = i;
            for (let i in this.loginForm) this.loginForm[i] = '';
        },
        //登录提交
        loginSubmit (e) {
            e.preventDefault();
            let that = this;
            let loginForm = that.loginForm;
            if (!loginForm.account) return that.$message.error('请填写账号');
            if (!loginForm.password) return that.$message.error('请填写密码');
            //账号密码登录
            that.$axios
                .post(that.$api.user.login, {
                    subsidiary_account: loginForm.account,
                    subsidiary_password: loginForm.password,
                })
                .then((res) => {
                    if (res.code == 0) {
                        that.$message({
                            message: '登录成功',
                            type: 'success',
                        });
                        that.getremember(that.is_remember);
                        localStorage.setItem('token', res.result.token);
                        this.$store.dispatch('user/getUserInfo')
                        that.$router.push('/');
                    } else {
                        that.$message.error(res.msg);
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    height: 100%;
    width: 100%;
    background: url('~@/assets/image/login-back.jpg') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .logo {
        width: 100%;
    }

    .address {
        width: 100%;
        text-align: center;
        color: #999;
        font-size: 16px;
        bottom: 60px;
    }

    .login-box {
        width: 440px;
        height: 480px;
        background: #ffffff;
        box-shadow: 0px 4px 24px 0px rgba(141, 141, 153, 0.16);
        border-radius: 4px;
        margin-right: 280px;

        .login {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .title {
                width: 320px;

                p:nth-child(1) {
                    font-size: 28px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #4c64ff;
                    margin-bottom: 5px;
                }

                p:nth-child(2) {
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    opacity: 0.5;
                }
            }

            .form {
                width: 320px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .password-input {
                margin-top: 50px;
                width: 100%;

                .account {
                    margin-bottom: 20px;
                }

                .account,
                .code {
                    margin-bottom: 32px;

                    .el-input {
                        width: 100%;
                    }
                }

                .forget-password {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    padding-left: 5px;

                    .left {
                        display: flex;
                        align-items: center;
                        color: #333;

                        .el-checkbox {
                            margin-right: 8px;

                            .el-checkbox__inner {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }

                    .right {
                        color: #999;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.login-btn {
    margin-top: 35px;
    text-align: center;

    button {
        width: 320px;
        height: 45px;
        background: #4c64ff;
        box-shadow: 0px 4px 24px 0px rgba(51, 68, 184, 0.3);
        border-radius: 28px;
        border: 0;
        color: #fff;
        font-size: 18px;
        line-height: 45px;
    }
}
</style>
